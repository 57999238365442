.root {
    padding: var(--pading-button-small);
    border-radius: var(--rad-button-medium);
    cursor: pointer;
    width: 100%;
    height: 40px;
}

.primary {
    background: var(--primary-button-bg);
    color: var(--primary-button-color);
}

.secondary {
    background: var(--secondary-button-bg);
    color: var(--secondary-button-color);
    border: 1px solid var(--secondary-button-border-color)
}

.small {
    font-size: var(--fs-small);
    line-height: var(--lh-small);
}

.large {
    font-size: var(--fs-header);
    padding: var(--pading-button-large);
}

.auth {
    padding: 6px 20px;
    width: 121px;
}

.shadow {
    box-shadow: 0 1px 2px var(--primary-button-shadow);
}

.disabledButtonWhite {
    background-color: #fafafa;
    pointer-events: none;
    opacity: 0.6;
}
.disabledButtonRed {
    background-color: #F08080;
    pointer-events: none;
    opacity: 0.6;
}

.selectedButton {
    background-color: var(--primary-button-dark-red);
}


@media screen and (min-width: 710px) and (max-width: 900px) {
    .large {
        font-size: var(--fs-small);
    }
}

@media screen and (min-width: 550px) and (max-width: 710px) {
 .large {
     padding: 5px;
     font-size: var(--fs-small);
 }
}



@media screen and (min-width: 360px) and (max-width: 550px) {
    .large {
        padding: 5px;
        font-size: var(--fs-microscopic);
    }

    .small {
        padding: 5px;
        font-size: var(--fs-microscopic);
    }
}
