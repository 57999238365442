.root {
    width: 690px;;
    height: 300px;
    text-align: center;
    overflow: auto;
    margin: 15px;
}

.title {
    font-weight: 600;
    font-size: var(--fs-payment-header);
    line-height: 31px;
    color: var(--secondary-button-color);
    margin-bottom: 20px;
    text-align: center;
}

.text {
    font-weight: 400;
    font-size: var(--fs-big);
    line-height: 23px;
    text-align: center;
    color: var(--tariff-line);
    margin-bottom: 40px;
    overflow: auto;
}

.buttonContainer {
    display: flex;
    justify-content: end;
}

.button {
    width: 120px;
}

.icon {
    width: 84px;
    height: 84px;
    margin: 0 auto;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.date {
    text-align: center;
    font-size: var(--fs-big);
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .root {
        width: 590px;
    }
    .text {
        font-size: 14px;
    }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .root {
        width: 340px;
        height: max-content;
        margin: 0;
    }
}