.root {
    width: 528px ;
}

.switchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}

.buttonContainer{
    display: flex;
    justify-content: end;
}
.ipDateDisabled {
    font-size: var(--fs-medium);
    font-weight: 500;
    color: var(--window-list-color);
    margin-bottom: 24px;
}

.mainButton {
    width: 120px;
}

.ipAddress {
    font-size: var(--fs-big);
    line-height: 20px;
    font-weight: 500;
    color: var(--window-list-color);
}

.textInfo {
    font-size: var(--fs-medium);
    line-height: 20px;
    font-weight: 400;
    color: var(--window-list-color);
}

.indent {
    height: 16px;
}

.content {
    font-size: var(--fs-medium);
    line-height: 20px;
    font-weight: 400;
    color: var(--window-list-color);
}


@media screen and (min-width: 600px) and (max-width: 700px) {
    .root {
        width: 500px;
    }

}

@media screen and (min-width: 425px) and (max-width: 600px) {
    .root {
        width: 400px;
    }

}

@media screen and (min-width: 320px) and (max-width: 424px) {
    .root {
        width: 340px;
    }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .ipAddress {
        font-size: 14px;
    }

    .switchContainer {
        justify-content: space-around;
    }
}





