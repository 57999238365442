@font-face {
    font-family: 'SB Sans Display Semibold';
    font-weight: 600;
    font-style: normal;
    src: url('/src/static/fonts/SB Sans Display Semibold.ttf') format('ttf');
}
@font-face {
    font-family: 'SB Sans Text';
    font-weight: 400;
    font-style: normal;
    src: url('/src/static/fonts/SB Sans Text.ttf') format('ttf');
}