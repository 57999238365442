.root {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.inputsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 32px 20px;
    margin-bottom: 40px;
}

.title {
    font-weight: 600;
    font-size: var(--fs-balance-money);
    line-height: 38px;
    margin: 18px 0 32px 0;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}
.tgBot {
    margin: 15px 0;
    color: var(--payment-lable-color);
}

.button {
    width: 168px;
}

.link {
    text-decoration: none;
 }

 .address {
     width: 100%;
     margin-bottom: 30px;
 }

 .tgToken {
     margin-bottom: 20px;
     color: var(--payment-lable-color);
 }

@media screen and (min-width: 360px) and (max-width: 550px){
    .tgToken {
        font-size: var(--fs-large-extended);
    }

}