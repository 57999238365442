.root {
   cursor: pointer;
}

.icon {
   width: 84px;
   height: 84px;
   margin: 0 auto;
   box-shadow: 0 4px 60px rgba(0, 0, 0, 0.1);
   border-radius: 20px;
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
}

.title {
   font-weight: 600;
   font-size: 24px;
   line-height: 31px;
   color: #000000;
   padding-top: 24px;
   padding-bottom: 16px;
   height: 62px;
   overflow: hidden;
}

.text {
   font-weight: 400;
   font-size: 18px;
   line-height: 23px;
   text-align: center;
   color: #606A73;
   height: 50px;
   overflow: hidden;
}

.date {
   margin-bottom: 10px;
   font-size: var(--fs-medium);
   font-weight: 600;
}