.root {
    display: flex;
    flex-direction: column;
    width: 650px;
}

.iconContainer {
    display: flex;
    justify-content: center;
}

.icon {

}

.content {
    font-size: var(--fs-medium);
    line-height: 20px;
    font-weight: 400;
    color: var(--window-list-color);
    margin-bottom: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
}



.moment {
    margin-top: 20px;
}

.button {
    width: 121px;
}


.buttonDisabled {
    pointer-events: none;
    opacity: 0.6;
}

.promisedMessage {
    font-weight: 600;
    margin-bottom: 20px;
}

@media screen and (min-width: 550px) and (max-width: 768px) {
    .root {
        width: 450px;
    }
    .content {
        font-size: var(--fs-small);
        line-height: 15px;
    }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .root {
        width: 340px;
    }
    .content {
        font-size: var(--fs-small);
        line-height: 15px;
    }

    .icon {
        height: 80px;
    }

    .promisedMessage {
        font-size: var(--fs-header);
    }

    .content {
        font-size: var(--fs-microscopic);
        line-height: 15px;
    }
}