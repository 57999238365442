.root {
    width: 518px ;
}

.metodContainer {
    display: flex;
    align-items: center;
}

.mainPayment {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.mainButton {
    width: 120px;
}

.name {
    font-size: var(--fs-big);
    color: var(--window-head-color);
    font-weight: 600;
}

.comment {
    font-size: var(--fs-medium);
    font-weight: 400;
    color: var(--window-list-color);
}

.rub {
    margin-left: 16px;
    line-height: 18px;
    font-size: 14px;
}

.inputContainer {
    width: 100%;
}

.buttonContainer {
    display: flex;
    justify-content: end;
}

.base64Png {
    display: flex;
    justify-content: center;
}

.qrInfoText {
    font-size: 14px;
}

.qrContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

@media screen and (min-width: 425px) and (max-width: 600px) {
    .root {
        width: 400px;
    }

}

@media screen and (min-width: 320px) and (max-width: 424px) {
    .root {
        width: 340px;
    }

    .name {
        font-size: var(--fs-header)
    }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .name {
        font-size: var(--fs-large)
    }
}

