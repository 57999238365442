.mainTariff {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.1);
    padding: 10px 20px
}

.buttonContainer {
    display: flex;
}

.buttonSecondary {
    margin-right: 10px;
    width: 160px;
}

.buttonPrimary {
    width: 160px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .buttonContainer {
        flex-direction: column;
    }

    .buttonSecondary {
        margin-bottom: 10px;
        margin-right: 0;
    }
}
