.root {
    display: grid;
    grid-template-columns: repeat(8, 120px);
    grid-template-rows: repeat(4, 1fr);
    gap: 10px;
    width: 100%;
    height: 570px;
    overflow: auto;
}

.name {
    height: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    word-wrap: break-word;
    font-size: var(--fs-header);
    color: var(--secondary-button-color);
}

.icon {
    width: 120px;
    height: 60px;
}

.listContainer {
    border: 1px solid var(--payment-border);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    background-color:  var(--bg-header); /* TODO  Палитра */
    border-radius: 10px;
}

.listItem {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 65px;
    width: 80px;
}

.price {
    font-weight: 400;
    font-size: var(--fs-big);
    line-height: 23px;
    color: var(--tariff-line);
    margin-bottom: 10px;
}

.short {
    font-size: var(--fs-big);
    font-weight: 600;
    line-height: var(--lh-medium);
    letter-spacing: 0.0025em;
    color: var(--window-head-color);
}

@media screen and (min-width: 900px) and (max-width: 1050px){
    .root {
        height: 470px;
        grid-template-columns: repeat(6, 120px);
    }
}

@media screen and (min-width: 750px) and (max-width: 900px){
    .root {
        height: 470px;
        grid-template-columns: repeat(5, 120px);
    }
}

@media screen and (min-width: 550px) and (max-width: 750px){
    .root {
        height: 470px;
        grid-template-columns: repeat(5, 95px);
    }
}

@media screen and (min-width: 425px) and (max-width: 550px){
    .root {
        height: 470px;
        grid-template-columns: repeat(4, 90px);
    }
}

@media screen and (min-width: 360px) and (max-width: 420px){
    .root {
        height: 470px;
        grid-template-columns: repeat(3, 90px);
    }
}