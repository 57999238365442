.formInput{
    margin-top: 32px;
    width: 100%;
}

.formItem {
    margin-bottom: 12px;
}

.icon {
    position: relative;
    top: 70px;
    left: 540px;
}
.message {
    margin-bottom: 12px;
    color: red;
}

.politika {
    margin-bottom: 15px;
    color: var(--payment-lable-color);
    font-size: var(--fs-large);
}

@media screen and (min-width: 750px) {
    .formInput {
        width: 580px;
    }

}


@media screen and (min-width: 320px) and (max-width: 550px) {
    .formInput {
        font-size: 14px;
    }
}
