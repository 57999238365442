.root {
    margin-top: 18px;
}

.title {
    font-size: var(--fs-balance-money);
    line-height: 50px;
    font-weight: 600;
    color: var(--auth-header-color);
    margin-bottom: 24px;
}


.subTitle {
    font-size: var(--fs-payment-header);
    line-height: 26px;
    font-weight: 400;
    color: var(--reserve-text-color);
}

.brands {
    display: flex;
    align-items: center;
    gap: 20px;
}


.bannersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerText {
    font-weight: 400;
    font-size: var(--fs-small);
    line-height: 26px;
    color: var(--reserve-text-color);
}

.image {
    width: 150px;
}

@media screen and (max-width: 1300px){
    .image {
        width: 150px;
    }

}


@media screen and (min-width: 900px) and (max-width: 1024px){
    .image {
        width: 130px;
    }
}

@media screen and (min-width: 750px) and (max-width: 890px){
    .image {
        width: 120px;
    }

    .imageTechno {
        width: 300px;
    }
}

@media screen and (min-width: 550px) and (max-width: 770px){
    .image {
        width: 110px;
    }

    .brands {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }

    .imageTechno {
        width: 350px;
    }

}

@media screen and (min-width: 425px) and (max-width: 550px){
    .image {
        width: 120px;
    }

    .imageTechno {
        width: 200px;
    }

    .brands {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }

    .title {
        font-size: var(--fs-prommise-header);
    }

    .subTitle {
        font-size: var(--fs-large-extended);
    }

    .imageTechno {
        width: 290px;
    }
}

@media screen and (min-width: 360px) and (max-width: 425px){
    .image {
        width: 100px;
    }

    .imageTechno {
        width: 240px;
    }

    .brands {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }
    .title {
        font-size: var(--fs-prommise-header);
    }

    .subTitle {
        font-size: var(--fs-large-extended);
    }
}

