.root {
    margin: 10px 48px;
}

.container {
    display: flex;
    justify-content: space-between;
}
.logo {
    cursor: pointer;
}

.contacts {
    font-style: normal;
    font-weight: 400;
    font-size: var(--fs-medium);
    line-height: 18px;
    text-align: right;
    color: var(--secondary-button-color);
}

.link {
    color: var(--secondary-button-color);
    text-decoration: none;
}

.general {
    color: var(--primary-button-bg);
}


@media screen and (min-width: 480px) and (max-width: 700px) {
    .root {
        margin: 10px 20px;
    }

    .logo {
        width: 120px;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .root {
        margin: 10px 20px;
    }
    .logo {
        width: 110px;
    }

    .contacts {
        font-size: var(--fs-small);
        margin-top: 5px;
    }
}