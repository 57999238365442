.root {
    box-shadow: 0 4px 60px var(--drop-down-extra-shadow);
    border-radius: 20px;
    margin-bottom: 24px;
}

.mainContainer {
    display: grid;
    grid-template-columns: 1fr 1.7fr 1fr 0.5fr;
    align-items: center;
}

.name {
    display: flex;
    align-items: center;
    margin: 0 0 0 16px;
    font-size: var(--fs-payment-header);
    line-height: 38px;
    font-weight: 600;
    color: var(--primary-button-bg);
    width: 200px;
}

.buttonContainer {
    display: inline-grid;
    grid-template-columns: repeat(3, 2fr);
    align-items: center;
    gap: 30px;
    margin-bottom: 15px;
}

.messageText {
    color: var(--auth-header-color);
    font-size: var(--fs-big);
}


.countChanel {
    display: grid;
    grid-template-columns: 1px 16px 0.9fr 1px;
    gap: 15px;
    align-items: center;
    cursor: pointer;
}

.lineTv {
    border: solid 1px var(--secondary-button-color);
    height: 64px;
    margin: 16px 0;
}

.count {
    font-size: var(--fs-payment-header);
    line-height: 31px;
}

.rootPriceContainer {
    display: flex;
}

.price {
    font-size: var(--fs-extra-money);
    line-height: 26px;
    color: var(--tariff-line);
    margin-right: 5px;

}


.priceType {
    font-size: var(--fs-small);
    line-height: 15px;
    color: var(--tariff-line);
    margin-left: 8px;
}

.requiredMessage {
    margin: 8px;
    font-size: var(--fs-big);
    width: 300px;
}

.switcher {
    display: flex;
    justify-content: end;
    margin-right: 12px;
}

.title {
    height: 50px;
    font-weight: 600;
    font-size: var(--fs-balance-money);
    line-height: 50px;
    color: var(--auth-header-color);
    margin-bottom: 15px;
}


@media screen and (min-width: 950px) and (max-width: 1300px){
    .name {
        font-size: var(--fs-prommise-header);
    }

    .count {
        font-size: var(--fs-big);
    }

    .price {
        font-size: var(--fs-auth-header);
    }
}

@media screen and (min-width: 500px) and (max-width: 950px){
    .name {
        width: 150px;
        font-size: var(--fs-large-extended);
    }

    .count {
        font-size: var(--fs-medium);
        line-height: 18px;
    }

    .price {
        font-size: var(--fs-prommise-header);
    }

    .priceContainer {
        flex-direction: column;
    }

    .priceType {
        margin: 0;
    }

    .requiredMessage {
        font-size: 14px;
        left: 16px;
    }

    .button {
        width: 130px;
    }

    .title {
        font-size: var(--fs-prommise-header);
    }
}

@media screen and (min-width: 360px) and (max-width: 500px){
    .root {
        display: grid;
    }

    .mainContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .requiredMessage {
        font-size: var(--fs-microscopic);
        line-height: 12px;
        margin: 10px;
    }

    .name {
        width: 150px;
        font-size: var(--fs-large-extended);
    }

    .price {
        font-size: var(--fs-prommise-header);
    }

    .count {
        font-size: var(--fs-medium);
        line-height: 18px;
    }

    .priceContainer {
        flex-direction: column;
        margin-left: 0;
    }

    .priceType {
        margin: 0;
    }

    .button {
        width: 70px;
    }


    .countChanel {
        display: grid;
        grid-template-columns: 16px 1fr;
        margin: 0 16px 6px 16px;
        align-items: center;
        cursor: pointer;
        gap: 5px;
    }

    .rootPriceContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
    }

    .requiredMessage {
        margin: 8px;
        font-size: var(--fs-small);
        width: 250px;
    }

    .title {
        font-size: 18px;
    }
}
