.root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.navHome {
    display: flex;
    justify-content: end;
    align-items: center;
}

.fio {
    background-color: var(--logout-bg);
    color: var(--fio-text-color);
    font-size: var(--fs-small);
    font-weight: 600;
}

.logout {
    cursor: pointer;
}

.iconWords {
    cursor: pointer;
}

.link {
    display: flex;
    gap: 10px;
    text-decoration: none;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .navHome {
        width: 100%;
    }
}
