.container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.window {
    background:  var(--bg-window);
    border-radius: 16px;
    z-index: 1;
}

.overlay {
    background-color: var(--overlay-window);
    opacity: 60%;
    position: absolute;
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px
}

.title {
    font-size: var(--fs-prommise-header);
    font-weight: 600;
    line-height: var(--lh-medium);
    letter-spacing: 0.0025em;
    color: var(--window-head-color);
}

.closeButton {
    cursor: pointer;
}

.content {
    padding: 24px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .content {
        padding: 5px;
    }

    .header {
        padding: 20px 12px
    }

    .title {
        font-size: var(--fs-header);
    }
}