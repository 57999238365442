.root {
   font-style: normal;
   font-weight: 600;
   font-size: var(--font-size-xxxl);
   line-height: 50px;
   color: var(--auth-header-color);
   padding: 16px 0;
   margin-top: 40px;
   margin-bottom: 10px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
   .root {
      margin-top: 25px;
      padding: 0;
   }
}
