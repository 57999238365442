.root {
    margin-bottom: 24px;
}

.titleHeader {
    font-size: var(--fs-balance-money);
    color: var(--balance-money-color);
    text-align: center;
    margin-top: 20px;
}

.bodyContainer {
    margin-top: 30px;
    margin-bottom: 48px;
}

.title {
    font-size: var(--fs-balance-money);
    font-weight: 600;
    line-height: 50px;
    color: var(--balance-money-color);
}

.textContainer {
    font-size: var(--fs-big);
    font-weight: 400;
    line-height: 30px;
    color: var(--tariff-line);
}

.textEnd {
    margin-bottom: 24px;
}

.possibilities {
    margin-left: 20px;
}

.important {
    margin-left: 20px;
}

@media screen and (min-width: 360px) and (max-width: 700px){
    .titleHeader {
        font-size: var(--fs-prommise-header);
    }
}