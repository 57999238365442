.root {
   text-align: left;
   display: flex;
   justify-content: space-between;
}

.left,
.right {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.name {
   font-weight: 600;
   font-size: var(--fs-balance-money);
   line-height: 38px;
   color: var(--primary-button-bg);
}

.price {
   display: flex;
   align-items: center;
   color: var(--tariff-line);
}
.amount {
   font-style: normal;
   font-weight: 400;
   font-size: var(--fs-balance-money);
   line-height: 38px;
}

.currency {
   font-style: normal;
   font-weight: 400;
   font-size: var(--fs-small);
   line-height: 15px;
   text-transform: lowercase;
   color: var(--tariff-line);
   padding-left: 4px;
   height: 31px;
   display: flex;
   flex-direction: column;
}

.line {
   height: 1px;
   background: var(--tariff-line);
   margin: 0 60px;
}

.channels {
   font-style: normal;
   font-weight: 400;
   font-size: var(--fs-big);
   line-height: 23px;
   text-decoration-line: underline;
   cursor: pointer;
   color: var(--tariff-line);
   padding-top: 24px;
}

@media screen and (max-width: 1060px) {
   .name {
      font-weight: 400;
      font-size: 20px;
      line-height: 38px;
   }
}

@media screen and (max-width: 850px) {
   .name {
      font-weight: 400;
      font-size: 16px;
      line-height: 38px;
   }
   .channels {

   }
}