.root {
    width: 518px;
}

.textHeader {
    font-size: 25px;
    font-weight: 600;
    line-height: 28px;
    color: var(--balance-money-color);
}

.listContainer {
    font-weight: 400;
    font-size: var(--fs-large);
    line-height: 20px;
    color: var(--reserve-text-color);
    margin: 0 0 16px 20px;
}

.buttonContainer {
    display: flex;
    justify-content: end;
    margin-top: 32px;
}

.button {
    width: 122px;
}

.indent {
    height: 16px;
}

.content {
    font-size: var(--fs-medium);
    line-height: 20px;
    font-weight: 400;
    color: var(--window-list-color);
}

@media screen and (min-width: 320px) and (max-width: 425px) {
    .root {
        width: 350px;
    }
    .content {
        font-size: var(--fs-small);
    }
}

@media screen and (min-width: 425px) and (max-width: 550px) {
    .root {
        width: 400px;
    }
    .content {
        font-size: var(--fs-small);
    }
}


