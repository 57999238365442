.root {
    width: 566px;
}

.inputPhone {
    width: max-content;
    height: 48px;
    padding: 12px;
    background-color: var(--primary-button-color);
    border: 1px solid var(--payment-border);
    box-sizing: border-box;
    border-radius: var(--rad-button-large);
    display: flex;
    align-items: center;
}

.buttonWidth {
    width: 119px;
}

.buttonContainer{
    display: flex;
    justify-content: end;
    margin-top: 70px;
}

.textArea {
    resize: none;
    background-color: var(--primary-button-color);
    border: 1px solid var(--payment-border);
    border-radius: var(--rad-button-large);
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    padding-top: 5px;
    padding-left: 10px;
    color: var(--payment-lable-color);
    font-size: var(--fs-large);
    font-weight: 400;
    line-height: 20px;
}

.phone {
    margin-top: 16px;
}

.error {
    margin-top: 16px;
    color: red;
}

@media screen and (min-width: 320px) and (max-width: 424px) {
    .root {
        width: 340px;
    }
    .textArea {
        font-size: var(--fs-small);
    }

}

@media screen and (min-width: 425px) and (max-width: 550px) {
    .root {
        width: 380px;
    }

    .textArea {
        font-size: var(--fs-small);
    }

}

@media screen and (min-width: 550px) and (max-width: 700px) {
    .root {
        width: 500px;
    }

}
