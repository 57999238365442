.root {
    display: flex;
}

.tariffContainer {
    cursor: pointer;
}

.tariffPencil {
    display: flex;
}

.tariffName {
    font-size: var(--fs-small);
    font-weight: 500;
    line-height: var(--lh-small);
    color: var(--auth-header-color);
    margin-bottom: 8px;
    margin-right: 8px;
}

.tariffSpeed {
    font-size: var(--fs-payment-header);
    font-weight: 600;
    color: var(--primary-button-bg);
    line-height: 31px;
}

.tariffCount {
    display: flex;
}

.line {
    border-left: 1px solid var(--tariff-line);
    margin: 0 16px;

}

.tariffMoney {
    display: flex;
    align-items: end;
    font-size: var(--fs-big);
    font-weight: 600;
    line-height: 23px;
    color: var(--tariff-line);
}

.moneyCount {
    margin-right: 4px;
}


@media screen and (min-width: 320px) and (max-width: 550px) {
    .tariffMoney {
        font-size: 12px;
    }

    .tariffSpeed {
        font-size: 20px;
    }
}