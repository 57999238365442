@import 'reset.css';
@import 'fonts.css';
@import 'variables.css';
@import "~leaflet/dist/leaflet.css";


.container {
    margin: 0 96px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .container {
        margin: var(--main-margin);
    }
}
