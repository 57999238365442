.mainContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
    margin-bottom: 15px;
    box-shadow: 0 4px 60px var(--drop-down-extra-shadow);
    border-radius: 20px;
    align-items: center;
    font-size: var(--fs-large);
}

.line {
    border: solid 1px var(--secondary-button-color);
    margin-top: 32px;
}

.bodyContainer {
    display: flex;
}
.mobileDate {
    color: var(--tariff-line);
    margin-left: 3px;
}

.sum {
    font-size: var(--fs-header);
    color: var(--tariff-line);
    margin-right: 3px;
}

.balance {
    font-size: var(--fs-header);
    color: var(--tariff-line);
    margin-right: 3px;
}
.textInfo {
    font-size: var(--fs-header);
    color: var(--tariff-line);
    margin-right: 3px;
}

.line1 {
    border: solid 1px var(--secondary-button-color);
    margin-bottom: 35px;
}

.icon {
    margin-left: 10px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .mainContainer {
        gap: 6px;
    }
}

