:root {
    /*Colors */

    --primary-button-bg: #FF0000;
    --primary-button-color: #FFFFFF;
    --primary-button-shadow: rgba(31, 41, 55, 0.08);
    --primary-button-dark-red: #C70000;

    --secondary-button-bg: #FFFFFF;
    --secondary-button-color: #000000;
    --secondary-button-border-color: #929298;

    --logo-color: var(--primary-button-bg);
    --bg-window: var(--primary-button-color);
    --icon-bell-fill: var(--secondary-button-bg);
    --around-text-color: var(--primary-button-color);
    --drop-down-color: var(--payment-header-color);
    --payment-header-color: rgba(30, 41, 59, 1);
    --drop-down-bg: rgba(30, 41, 59, 0.15);
    --drop-down-extra-shadow: rgba(0, 0, 0, 0.1);

    --bg-header: #F3F5F7;
    --overlay-window: #333F46;
    --window-head-color: #0C1524;
    --window-list-color: rgba(0, 0, 0, 0.6);
    --balance-money-color: #1E293B;
    --payment-lable-color: #334155;
    --payment-border: #E2E8F0;
    --logout-fill: #8687A3;
    --logout-bg: #F7F8F9;
    --select-feedback-bg: var(--logout-bg);
    --auth-header-color: #333546;
    --tariff-line: #606A73;
    --reserve-text-color: #64748B;
    --upload-text-color: #94A3B8;
    --fio-text-color: #484966;
    --Status-Draft-BG: #FFE3E3;
    --Status-Draft-TEXT: #334155;
    --site-sales-text: #C4C4C4;

    /* Size */
    --fs-microscopic: 10px;
    --fs-verySmall: 11px;
    --fs-small: 12px;
    --fs-header: 13px;
    --fs-medium: 14px;
    --fs-large: 15px;
    --fs-large-extended: 16px;
    --fs-big: 18px;
    --fs-prommise-header: 20px;
    --fs-payment-header: 24px;
    --fs-auth-header: 26px;
    --fs-balance-money: 30px;
    --fs-balance-money-extended: 32px;
    --fs-extra-money: 34px;
    --fs-extra-money-extended: 38px;
    --fs-super-bigger: 50px;

    --lh-small: 16px;
    --lh-medium: 24px;

    --pading-button-small: 6px 29px;
    --pading-button-large: 9px 20px;

    /* Border radius */

    --rad-button-small: 4px;
    --rad-button-medium: 6px;
    --rad-button-large: 8px;

    /* Margin */

    --main-margin: 0 48px ;
    --main-margin_2: 0 96px ;
    --font-size-xxxl: 50px
}



@media screen and (max-width: 750px) {
    .root-container {
        --font-size-xxxl: var(--fs-extra-money-extended);
        --fs-big: var(--fs-header);
        --fs-payment-header: var(--fs-prommise-header);
        --fs-medium: var(--fs-header)
    }
}

@media screen and (max-width: 850px) {
    .root-container {
        --fs-balance-money: var(--fs-auth-header);
        --fs-big: var(--fs-small);
        --pading-button-small: 6px 20px;
        --fs-payment-header: var(--fs-prommise-header);
    }
}

@media screen and (min-width: 550px) and (max-width: 700px) {
    .root-container {
        --main-margin: 0 20px;
        --fs-super-bigger: var(--fs-balance-money-extended);
    }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .root-container {
        --main-margin: 0 9px;
        --fs-large: var(--fs-small);
        --fs-header: var(--fs-small);
        --fs-super-bigger: var(--fs-auth-header);
        --fs-balance-money: var(--fs-medium);
    }
}
