.container {

}

.titleInfo {
    font-size: var(--fs-prommise-header);
    color: var(--auth-header-color);
    font-weight: 600;
    margin: 10px 0 10px;
}

.documentContainer {
    display: flex;
    align-items: center;
}

.icon {
    width: 24px;
    height: 24px;
}

.documentText {
    margin-left: 10px;
    font-size: var(--fs-medium);
    color: var(--secondary-button-color);
    font-weight: 400;
    text-decoration: none;
}
