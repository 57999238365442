.line {
    border: solid 1px var(--secondary-button-color);
    margin: 24px 0;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    height: 50px;
    font-weight: 600;
    font-size: var(--fs-balance-money);
    line-height: 50px;
    color: var(--auth-header-color);
}

.active {
    rotate: 180deg;
    cursor: pointer;
}

.inactive {
    rotate: 0deg;
    cursor: pointer;
}

@media screen and (min-width: 360px) and (max-width: 550px){
    .title {
        font-size: var(--fs-large-extended);
    }

}
