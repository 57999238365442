.root {
   background: var(--primary-button-color);
   border-radius: 20px;
   text-align: center;
   padding: 32px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
   .root {
      padding: 16px;
   }
}
