.infoContainer {
    display: grid;
    width: 890px;
    grid-template-columns: 1fr 1fr;
    gap: 20px 20px;
}

.buttonContainer {
    display: flex;
    justify-content: end;
}

.button {
    width: 168px;
}

.comment {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 48px;
}

.alert {
    color: red;
}

@media screen and (max-width: 768px) {
    .infoContainer {
        width: 590px;
    }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .infoContainer {
        width: 340px;
        gap: 5px 5px;
    }
    .alert {
        font-size: var(--fs-microscopic);
    }
}
