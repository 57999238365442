.root {
    width: 1024px ;
}


.trContainer {
    display: grid;
    grid-template-columns: 425px 2fr 1.5fr 1fr 1fr 1fr;
    margin-bottom: 15px;
    box-shadow: 0 4px 60px var(--drop-down-extra-shadow);
    border-radius: 20px;
    align-items: center;
    font-size: var(--fs-large);
    text-align: center;
}


.info {
    text-align: left;
}