.container {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-gap: 30px;
}

.textContainer {
   display: flex;
   flex-direction: column;
   background-color: var(--Status-Draft-BG);
   align-items: center;
   border-radius: 20px;
   padding: 10px;
   margin-bottom: 24px;
}

.mainText {
   font-size: var(--fs-medium);
   font-weight: 400;
   color: var(--Status-Draft-TEXT);
}

.secondText {
   font-size: var(--fs-microscopic);
   font-weight: 400;
   color: var(--Status-Draft-TEXT);
}

@media screen and (min-width: 320px) and (max-width: 550px) {
   .mainText {
      margin-bottom: 5px;
      font-size: var(--fs-small);
   }
   .container {
      grid-template-columns: 1fr 1fr;
      gap: 15px;
   }

}