.root {
    margin-bottom: 8px;
    font-weight: 400;
}

.primary {
    color: var(--payment-lable-color);
}

.secondary {
    color: #64748B;
}

.medium {
    font-size: var(--fs-medium);
    line-height: 18px;
}

.large {
    font-size: var(--fs-large);
    line-height: 20px;
}