.switchLabel {
    cursor: pointer;
    width: 90px;
    height: 50px;
    background: red;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
}

 .switchButton {
    position: relative;
    top: 2px;
    left: 2px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switchActive {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.switchOn {
    background: #06D6A0;
}

.switchDisable {
    pointer-events: none;
    opacity: 0.9;
    background: gray;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .switchLabel {
        width: 60px;
        height: 40px;
    }

    .switchButton {
        width: 35px;
        height: 35px;
    }
}

@media screen and (min-width: 650px) and (max-width: 800px) {
    .switchLabel {
        width: 70px;
        height: 40px;
    }

    .switchButton {
        width: 35px;
        height: 35px;
    }
}

@media screen and (min-width: 360px) and (max-width: 650px) {
    .switchLabel {
        width: 50px;
        height: 30px;
    }

    .switchButton {
        width: 25px;
        height: 25px;
    }
}
