.map {
   width: 100%;
   height: 516px;
   display: block;
}
.contacts {
   font-style: normal;
   font-weight: 400;
   font-size: var(--fs-medium);
   line-height: 20px;
   color: var(--secondary-button-color);
   padding-bottom: 24px;
}

.link {
   color: var(--secondary-button-color);
   text-decoration: none;
}

