.root {
    display: flex;
    align-items: center;
}

.textColor {
    font-size: var(--fs-big);
    line-height: 20px;
    font-weight: 500;
    color: var(--window-list-color);
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #aaa;
    background: #FFF;
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
    -webkit-transition: all .275s;
    transition: all .275s;
}

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: '✓';
    position: absolute;
    top: .525em;
    left: .18em;
    font-size: 1.375em;
    color: red;
    line-height: 0;
    -webkit-transition: all .2s;
    transition: all .2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
}

[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
}

/* Disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
}

[type="checkbox"]:disabled:checked + label:after {
    color: #777;
}

[type="checkbox"]:disabled + label {
    color: #aaa;
}

/* Accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(6, 214, 160);
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .textColor {
        font-size: 14px;
    }
}
