.container {
    display: grid;
    width: 890px;
    grid-template-columns: 1fr 1fr;
    gap: 20px 20px;
}

.buttonContainer {
    display: flex;
    justify-content: end;
}

.button {
    width: 168px;
}

.comment {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 48px;
}

.alert {
    color: red;
}

.politika {
    margin-bottom: 15px;
    color: var(--payment-lable-color);
    font-size: var(--fs-large);
}

@media screen and (max-width: 768px) {
    .container {
        width: 590px;
    }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .container {
        width: 340px;
        height: max-content;
    }

    .alert{
        font-size: var(--fs-microscopic);
    }
}