.root {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--rad-button-small);
}

.primary {
    background: var(--primary-button-bg);
}

.secondary {
    fill: var(--logout-fill);
    background: var(--logout-bg);
}

.round {
    color: var(--around-text-color);
    border-radius: 50%;
}
