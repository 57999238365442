.root {
    width: 100%;
    height: 48px;
    padding: 12px;
    background-color: var(--primary-button-color);
    border: 1px solid var(--payment-border);
    box-sizing: border-box;
    border-radius: var(--rad-button-large);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
}

.customSize {
    height: 107px;
}

.disabledInput {
    pointer-events: none;
    opacity: 0.9;
}

.radioSize {
    width: 40px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .root {
        height: 36px;
        font-size: var(--fs-small);
    }

    .radioSize {
        width: 30px;
    }
}