.root {
    background-color: white;
    padding: 10px 48px 20px 48px;
}

.containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    font-size: var(--fs-big);
}

.lkButton {
    background: var(--secondary-button-color);
    border-radius: 4px;
}

.item {
    cursor: pointer;
    color: black;
    padding: 5px;
}

.logo {
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .itemsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
    }
    .socialContainer {
        width: 100px;
        margin: auto;
    }
    .socialItem {
        margin: 0 5px;
    }
}

@media screen and (max-width: 768px) {
    .itemsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        font-size: var(--fs-header);
    }
    .socialContainer {
        width: 100px;
        margin: auto;
    }
    .socialItem {
        margin: 0 5px;
    }

}

@media screen and (min-width: 550px) and (max-width: 700px) {
    .root {
        padding: 10px 20px 20px 20px;
    }
    .logo {
        width: 100px;
    }
    .itemsContainer {
        display: grid;
        gap: 0;
    }
}

@media screen and (min-width: 320px) and (max-device-width: 550px) {
    .root {
        padding: 10px 12px 20px 12px;
    }
    .logo {
        width: 80px;
    }
    .itemsContainer {
        display: grid;
        gap: 0;
        font-size: 10px;
    }
    .socialItem {
        width: 40px;
    }
}
