.info {
    margin-top: 50px;
}

.enterText {
    width: 100%;
    margin-bottom: 20px;
    font-size: var(--fs-super-bigger);
    color: var(--auth-header-color);
}

.check {
    width: 100%;
    font-size: var(--fs-balance-money);
    color: var(--balance-money-color);
}
