.root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.test {
    text-align: center;
    font-size: var(--fs-big);
}