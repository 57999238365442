.root {
    display: flex;
    width: 100%;
    cursor: pointer;
    height: 396px;
}

.textContainer {
    background-color: var(--primary-button-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    width: 100%;
}

.titleText {
    font-weight: 600;
    font-size: var(--font-size-xxxl);
    color: var(--primary-button-color);
    margin-bottom: 30px;
}

.text {
    color: var(--primary-button-color);
    font-size: var(--fs-big);
}

@media screen and (max-width: 850px) {
    .titleText {
        font-size: 38px;
    }
    .text {
        font-size: var(--fs-large)
    }
}

@media screen and (max-width: 650px) {
    .titleText {
        font-size: 28px;
    }
    .text {
        font-size: var(--fs-header)
    }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .root {
        height: 250px;
    }
    .banner {
        width: 50%;
    }

}

