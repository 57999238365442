.root {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
}

.name {
   font-weight: 600;
   font-size: var(--fs-payment-header);
   line-height: 31px;
   color: var(--primary-button-bg);
   padding-bottom: 16px;
}

.price {
   padding-top: 16px;
   padding-bottom: 24px;
   font-weight: 400;
   font-size: var(--fs-big);
   line-height: 23px;
   text-align: center;
   color: var(--tariff-line);
}

.priceType {
   font-size: var(--fs-big);
}

.speed {
   font-size: var(--fs-big);
   font-weight: 600;
   line-height: 23px;
   color: var(--tariff-line);
}

.button {
   width: 120px;
   display: inline-block;
   align-content: center;
}

.line {
   height: 1px;
   background: var(--tariff-line);
}

@media screen and (max-width: 1060px) {
   .name {
      font-weight: 400;
      font-size: 20px;
   }
}
