.root {
    width: 550px;
}

.tariffInfo {
    display: flex;
    justify-content: space-between;
}

.tariff {
    font-size: var(--fs-payment-header);
    line-height: 28px;
    font-weight: 600;
    color: var(--balance-money-color);
}

.tariffContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /*display: flex;*/
    /*justify-content: space-between;*/
    align-items: center;
    margin-top: 18px;
    height: 60px;
}

.changeTariff {
    font-size: 8px;
}


.tariffName {
    font-size: var(--fs-payment-header);
    font-weight: 600;
    color: var(--primary-button-bg);
}

.price {
    font-weight: 400;
    font-size: var(--fs-big);
    color: var(--tariff-line);
    margin-right: 4px;
}
.priceType {
    color: var(--tariff-line);
}

.tariffLine {
    border: solid 1px var(--secondary-button-color);
}

.tariffSpeed {
    font-weight: 400;
    font-size: var(--fs-medium);
    line-height: 23px;
    color: var(--tariff-line);
}

.priceContainer {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.textInfo {
    font-size: var(--fs-large);
    line-height: 20px;
    font-weight: 400;
    color: var(--window-list-color);
}

.dataChoiceContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.picker {
    height: 48px;
    padding: 12px;
    border: 1px solid var(--payment-border);
    box-sizing: border-box;
    border-radius: var(--rad-button-large);
}

.disabledPicker {
    background-color: #fafafa;
}

.activePicker {
    background-color: var(--primary-button-color);
}

.pickerAlign {
    text-align: center;
    cursor: pointer;
}


.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.button {
    width: 122px;
}

.changeTariff {
    font-size: var(--fs-header);
    color: var(--tariff-line);
}

@media screen and (min-width: 320px) and (max-width: 424px) {
    .root {
        width: 340px;
    }

    .tariffName {
        font-size: 16px;
    }

    .price {
        font-size: var(--fs-large)
    }

    .priceType {
        font-size: 12px;
    }

    .changeTariff {
        font-size: var(--fs-verySmall);
    }

}

@media screen and (min-width: 425px) and (max-width: 550px) {
    .root {
        width: 400px;
    }

    .tariffName {
        font-size: 16px;
    }

    .price {
        font-size: var(--fs-large)
    }

    .priceType {
        font-size: 12px;
    }

    .changeTariff {
        font-size: var(--fs-verySmall);
    }

}

@media screen and (min-width: 550px) and (max-width: 800px) {
    .root {
        width: 500px;
    }

    .tariffName {
        font-size: 18px;
    }

    .price {
        font-size: var(--fs-large)
    }

    .priceType {
        font-size: 12px;
    }

    .changeTariff {
        font-size: var(--fs-verySmall);
    }

}
