.container {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 30px;

}

@media screen and (min-width: 320px) and (max-width: 550px) {
   .container {
      grid-template-columns: 1fr;
      grid-gap: 15px;
   }
}
